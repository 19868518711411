import { px2Rem } from '@/utils/px2Rem';
import { primaryColors } from '@/styles/variables/colours';
import styled from 'styled-components';

export const StyledNavPlaceholder = styled.div<{ $loggedIn: boolean }>`
  ${({ $loggedIn }) => !$loggedIn && `
    height: ${px2Rem(183)};
    background-color: ${primaryColors.b01};

    @media (min-width: 560px) {
      height: ${px2Rem(166)};
    }
    
    @media (min-width: 900px) {
      height: ${px2Rem(214)};
    }
  `}
`;
