import { StyledAccountBanner, StyledBackButton } from './styles';
import { ASSETS_SVGS_BASE_URL, Copy, Spacer } from '@raylo-tech/raylopay-ui';
import { primaryColors } from '../../styles/variables/colours';
import { AccountBannerProps } from './types';
import { baseUrl } from './constants';
import { useRouter } from 'next/router';
import { useAppContext } from '../../hooks/useAppContext';
import { useCustomerContext } from '../../hooks/useCustomerContext';
import { useConsumerTypeContext } from '@/hooks/useConsumerTypeContext';

const AccountBanner = ({ dataTestId, navigateBack }: AccountBannerProps) => {
  const router = useRouter();
  const { previousPageIsProducts } = useAppContext();
  const { eligibleForAddTech, eligibleForUpgrade } = useCustomerContext();
  const { consumerTypePrefixPath } = useConsumerTypeContext();

  const onClick = () => {
    if (!navigateBack) return;

    if (previousPageIsProducts) {
      router.back();
    } else {
      router.push(consumerTypePrefixPath('/products'));
    }
  };

  const bannerContext = navigateBack ? 'BACK' : eligibleForAddTech ? 'ADDITIONAL_TECH' : eligibleForUpgrade ? 'UPGRADE' : '';

  return (
    <StyledAccountBanner data-testid={`${dataTestId}-${navigateBack ? 'BACK' : bannerContext}`}>
      <div className="upgrade-subscription__container">
        <div className="upgrade-subscription__left">
          <StyledBackButton
            data-testid={`${dataTestId}-link`}
            as={navigateBack ? 'button' : 'a'}
            href={navigateBack ? undefined : `${baseUrl}/account`}
            onClick={onClick}
          >
            <img src={`${ASSETS_SVGS_BASE_URL}/icon--arrow--pink--left.svg`} alt="Arrow left icon" />
            <Copy color={primaryColors.p01} medium lineHeight={18} fontSize={14}>
              {navigateBack ? 'Back' : 'Account Overview'}
            </Copy>
          </StyledBackButton>
          <Spacer height={8} />
          {!navigateBack && eligibleForAddTech && (
            <Copy medium lineHeight={36} fontSize={24} color={primaryColors.w01} letterSpacing={0}>
              Add <span>tech</span>
            </Copy>
          )}
          {!navigateBack && eligibleForUpgrade && (
            <>
              <Copy medium lineHeight={36} fontSize={24} color={primaryColors.w01} letterSpacing={0}>
                Choose your <span>FREE upgrade</span>
              </Copy>
              <Spacer height={8} />
              <Copy lineHeight={20} fontSize={16} color={primaryColors.w01} letterSpacing={0}>
                Showing devices you&apos;re eligible to upgrade to
              </Copy>
            </>
          )}
        </div>
      </div>
    </StyledAccountBanner>
  );
};

export default AccountBanner;
